import { useWebConfig } from 'features/configuration/queries';
import { Award as AwardType } from 'types/Product';
import { Image } from 'ui';

interface AwardProps {
  award: AwardType;
}

const Award = ({ award }: AwardProps) => {
  const { data: webConfig } = useWebConfig();

  const imageQuality = webConfig?.imageQuality;

  const imageUrl = award?.image?.url;
  const code = award?.code;

  if (!imageUrl) {
    return null;
  }

  return <Image alt={`Award ${code}`} height={40} quality={imageQuality || 100} src={imageUrl} width={40} />;
};

export default Award;
