/**
 * Scrolling utility class
 *
 * @author janssco
 * @since 1.0
 */

import { MAIN_BAR_CONTAINER_ID } from 'constants/components';
import { MouseEvent } from 'react';
import { Behavior } from 'types/Scroll';

/**
 * Adds anchor tags functionality with scrolling to react-router links.
 *
 * @param {number} delay - The delay for the scroll check timeout.
 * @returns {undefined}
 */
export function hashLinkScroll(delay = 0) {
  const { hash } = window.location;

  if (hash !== '') {
    // Push onto callback queue so it runs after the DOM is updated,
    // this is required when navigating from a different page so that
    // the element is rendered on the page before trying to getElementById.
    setTimeout(() => {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);

      if (element) {
        window.scrollTo(0, element.offsetTop);
      }
    }, delay);
  }
}

/**
 * ScrollTo function used to make an anchor tag scroll to the specified target element id
 * @param {object} event - Default anchor event
 * @param {object} targetElement - ID of the element we have to scroll to
 * @returns {void} Scrolls user to desired element
 */
export const scrollTo = (event?: MouseEvent, targetElement?: string, offset?: number, behavior?: Behavior) => {
  event?.preventDefault();

  const headerOffset = offset || (document?.getElementById(MAIN_BAR_CONTAINER_ID)?.clientHeight ?? 0) + 50;

  if (typeof window !== 'undefined' && targetElement) {
    const element = document.getElementById(targetElement);

    if (element) {
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = headerOffset ? elementPosition - headerOffset : elementPosition;

      window.scrollBy({
        behavior,
        top: offsetPosition,
      });
    }
  }
};
