import { CURRENT_BRAND } from 'constants/brand';
import { CURRENT_LOADBEE_LOCALE_CONFIG } from 'constants/loadbee';
import { useCactConsentGroupAccepted } from 'hooks/useCactConsentGroupAccepted';
import useDynamicScript from 'hooks/useDynamicScript';
import useRouter from 'hooks/useRouter';

interface CMSLoadBeeComponentProps {
  qualifier: string;
}

const CMSLoadBeeComponent = ({ qualifier }: CMSLoadBeeComponentProps) => {
  const { locale } = useRouter();
  const consentAccepted = useCactConsentGroupAccepted('TARGETING');

  const scriptLocale = CURRENT_LOADBEE_LOCALE_CONFIG[locale].join(',');

  useDynamicScript({
    isEnabled: consentAccepted,
    scriptId: `loadbeeScript-${locale}`,
    src: 'https://cdn.loadbee.com/js/loadbee_integration.js',
  });

  return (
    <div className="container">
      <div
        className="loadbeeTabContent"
        data-loadbee-apikey={process.env.NEXT_PUBLIC_LOADBEE_API_KEY}
        data-loadbee-cat-products={`${process.env.NEXT_PUBLIC_MEDIA_URL}/sys-master/loadBee/loadbee-${CURRENT_BRAND}-${qualifier}.txt`}
        data-loadbee-catalogue={qualifier}
        data-loadbee-debug="false"
        data-loadbee-locale={scriptLocale}
        data-loadbee-template={`${qualifier}_catalogue`}
        id={`loadbee-${qualifier}-${locale}`}
      />
    </div>
  );
};

export default CMSLoadBeeComponent;
