import Script from 'next/script';
import { useEffect, useRef, useState } from 'react';
import { Text } from 'ui';
import { StyledCmsHtmlComponent } from './CMSHTMLComponent.styled';

/**
 * decodes the base64 encoded html content.
 *
 * @param {String} encoded - The base64 encoded html.
 * @returns {string} Decoded html.
 */
const decodeHtml = (encoded: string) => {
  if (typeof window === 'undefined') {
    // eslint-disable-next-line new-cap
    return Buffer.from(encoded, 'base64').toString();
  }

  return atob(encoded);
};

type CMSHTMLComponentProps = {
  content?: string;
  locale?: string;
  script?: string;
  withScriptEval?: boolean;
};

const CMSHTMLComponent = ({
  content: propContent,
  locale,
  script: propScript,
  withScriptEval = true,
}: CMSHTMLComponentProps) => {
  const htmlContentRef = useRef<HTMLDivElement>(null);
  const [scriptToLoad, setScriptToLoad] = useState(null);

  const reloadHTMLScripts = () => {
    if (htmlContentRef.current) {
      const scripts = htmlContentRef.current.getElementsByTagName('script');

      const HTMLScripts = Array.prototype.slice.call(scripts);

      if (HTMLScripts && HTMLScripts.length > 0) {
        HTMLScripts.forEach((HTMLScript) => {
          if (HTMLScript && HTMLScript.innerHTML) {
            setScriptToLoad(HTMLScript.innerHTML);
          } else if (HTMLScript && !HTMLScript.innerHTML && HTMLScript.src) {
            const body = document.querySelector('body');
            const script = document.createElement('script');
            script.src = HTMLScript.src;
            if (body) {
              HTMLScript.parentNode.removeChild(HTMLScript);
              body.appendChild(script);
            }
          }
        });
      }
    }
  };

  useEffect(() => {
    reloadHTMLScripts();
  }, [locale, withScriptEval, htmlContentRef.current]);

  let decodedContent;
  let decodedScript;

  if (propContent) {
    decodedContent = decodeHtml(propContent);
  }

  if (propScript) {
    decodedScript = decodeHtml(propScript);
  }

  if (!decodedScript && !decodedContent) return null;

  return (
    <StyledCmsHtmlComponent ref={htmlContentRef}>
      {decodedContent && <Text html={decodedContent} />}

      {decodedScript && <div dangerouslySetInnerHTML={{ __html: decodedScript }} />}

      {scriptToLoad && (
        <Script
          dangerouslySetInnerHTML={{
            __html: scriptToLoad,
          }}
          defer
          id="htmlscript"
        />
      )}
    </StyledCmsHtmlComponent>
  );
};

export default CMSHTMLComponent;
