import { useEffect } from 'react';

interface DynamicScriptProps {
  className?: string;
  defer?: boolean;
  innerHtml?: string;
  isEnabled?: boolean;
  scriptId: string;
  src?: string;
  type?: string;
}

const useDynamicScript = ({
  className,
  defer,
  innerHtml,
  isEnabled = true,
  scriptId,
  src,
  type,
}: DynamicScriptProps) => {
  useEffect(() => {
    if (isEnabled) {
      const head = document.querySelector('head');
      const script = document.createElement('script');
      script.setAttribute('id', scriptId);

      if (src) {
        script.setAttribute('src', src);
      }

      if (className) {
        script.setAttribute('className', className);
      }

      if (type) {
        script.setAttribute('type', type);
      }

      if (innerHtml) {
        script.innerHTML = innerHtml;
      }
      script.setAttribute(defer ? 'defer' : 'async', 'true');

      head?.appendChild(script);
    }

    return () => {
      const script = document.querySelector(`#${scriptId}`);
      script?.remove();
    };
  }, [src, isEnabled, innerHtml, scriptId]);

  return null;
};
export default useDynamicScript;
